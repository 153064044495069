<template>
    <form class="information-form" @submit="sendFormData" :key="key">
        <z-caption
            tag="h2"
            size="m"
            class="information-form__title u-bottom-margin--l-important"
        >Запрос информации</z-caption>

        <z-input
            class="u-bottom-margin--m-important"
            @input="onChange"
            maxlength="1500"
            name="description"
            type="text"
            placeholder="Описание"
            required
        ></z-input>

        <z-input-file
            class="u-bottom-margin--m-important"
            :multiple="true"
            @change="addFile"
            name="file"
            :accept="acceptTypes"
        ></z-input-file>

        <z-input
            class="u-bottom-margin--m-important"
            @input="onChange"
            name="fullName"
            type="text"
            placeholder="ФИО"
            required
        ></z-input>

        <z-input
            class="u-bottom-margin--m-important"
            @input="onChange"
            name="email"
            type="email"
            placeholder="Email"
            required
        ></z-input>

        <z-input
            class="u-bottom-margin--m-important"
            @input="onChange"
            name="phone"
            type="text"
            placeholder="Телефон"
            required
        ></z-input>

        <z-checkbox
            class="u-bottom-margin--m-important"
            @change="isChecked = !isChecked"
            size="s"
            name="checkbox"
            required
        >
            <p class="information-form__checkbox-caption">
                Я согласен с&nbsp;обработкой моих персональных данных в&nbsp;соответствии с&nbsp;<z-link :href="policyLink" external>Политикой конфиденциальности персональных данных</z-link>
            </p>
        </z-checkbox>

        <z-button
            class="information-form__button"
            :class="{ 'information-form__button--disabled': !isValid }"
            type="primary"
            >Отправить</z-button
        >

        <z-modal id="slotModal" @closeSuccessModal="closeModal" @close-modal="closeModal">
            <z-caption class="information-form__modal-title" tag="h3"
                >Сообщение получено!</z-caption
            >
            <z-caption class="information-form__modal-text" tag="p">
                После его регистрации на&nbsp;ваш e-mail будет отправлено уведомление с&nbsp;номером обращения.
            </z-caption>
        </z-modal>
    </form>
</template>

<script>
import { saveInformation } from '@/api/information-form.js'
import { showNotyfications } from '@/utils/notifications'

export default {
    name: 'information-form',

    data () {
        return {
            key: 1,
            fileList: [],
            isChecked: false,
            isLoading: false,
            formData: {
                title: 'Запрос информации',
                description: '',
                fullName: '',
                phone: '',
                email: ''
            },
            acceptTypes:
                'image/png, image/jpg, image/jpeg, image/gif, application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, .txt'
        }
    },

    computed: {
        isValid () {
            return (
                this.formData.fullName &&
                this.formData.description &&
                this.formData.email &&
                this.isChecked &&
                this.formData.phone
            )
        },

        policyLink () {
            return this.$root.lang === 'ru'
                ? '/investors/cookie-policy/'
                : '/en/investors/cookie-policy/'
        }
    },

    methods: {
        onChange (data) {
            this.formData[data.name] = data.value
        },

        addFile (event) {
            if (!event.value[0]) return
            const acceptTypesArray = this.acceptTypes.split(',').map((t) => t.trim())
            const splittedName = event.value[0].name.split('.')
            const type = event.value[0].type || splittedName[splittedName.length - 1]

            if (acceptTypesArray.includes(type)) {
                this.fileList = event.value
            }
        },

        closeModal () {
            window.location.href = '/contacts/feedback/'
        },

        async sendFormData () {
            if (this.isLoading) {
                return
            }

            if (this.isValid) {
                const data = new FormData()

                for (const key in this.formData) {
                    data.append(key, this.formData[key])
                }

                for (let file of this.fileList) {
                    data.append('file[]', file)
                }

                try {
                    this.isLoading = true
                    const response = await saveInformation(data)

                    if (response.public.status === 'error') {
                        showNotyfications(response.public.message, { type: 'error' })
                    } else {
                        this.$root.$bus.$emit('clear', 'description')
                        this.$root.$bus.$emit('clear', 'file')
                        this.$root.$bus.$emit('clear', 'fullName')
                        this.$root.$bus.$emit('clear', 'email')
                        this.$root.$bus.$emit('clear', 'phone')
                        this.$root.$bus.$emit('clear', 'checkbox')
                        this.formData = {
                            title: 'Запрос информации',
                            description: '',
                            fullName: '',
                            phone: '',
                            email: ''
                        }
                        this.isChecked = false
                        this.fileList = []
                        showNotyfications('Отправка успешна', { type: 'success' })
                        this.$root.$emit('open-modal', { id: 'slotModal', index: 0 })
                    }
                } catch (error) {
                    console.log(error)
                } finally {
                    this.isLoading = false
                }
            } else {
                showNotyfications('Заполните все обязательные поля', { type: 'error' })
            }
        }
    }
}
</script>

<style lang="scss">
.information-form {
    margin: 0 auto;

    .z-modal__window {
        top: 10%;
        max-height: 250px;
        max-width: 500px;
        font-size: 18px;
    }

    &__title {
        text-align: center;
    }

    &__checkbox-caption {
        font-size: 14px;

        .z-link {
            font-family: 'Fact';
            font-size: inherit;
        }
    }

    &__button {
        background-color: #ff9573 !important;
        margin: 0 auto !important;
        display: block !important;

        &--disabled {
            background-color: gray !important;
            cursor: default;

            &:hover {
                box-shadow: none !important;
            }

            &:active {
                background-color: gray !important;
            }
        }
    }

    &__modal {
        &-title {
            margin-bottom: 28px;
            color: $token-colors-crisp-white !important;

            @media screen and (min-width: 1240px) {
                font-size: 34px;
            }

            @media screen and (max-width: 767px) {
                font-size: 20px;
            }
        }

        &-text {
            line-height: 1.5;
            color: $token-colors-crisp-white !important;
        }
    }
}
</style>
